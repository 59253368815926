import React, { Component } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Link, graphql } from "gatsby";
import queryString from "query-string";

import { Layout } from "../components";
import { getFullAddress } from "../utils/cinema";

import styles from "./cinemap.module.css";

const renderMarker = (cinema) => (
  <Marker position={[cinema.address.lat, cinema.address.lon]} key={cinema.url}>
    <Popup>
      <span>
        <Link className={styles.link} to={`/cinema/${cinema.url}`}>
          {cinema.title}
        </Link>
        <br />
        <br />
        {getFullAddress(cinema)}
      </span>
    </Popup>
  </Marker>
);

class MapView extends Component {
  // Allows for searching with query params eg: /cinemap?zoom=5&lon=10&lat=20
  render() {
    const queryParams = queryString.parse(this.props.location.search);

    const cinemas = this.props.data.allContentfulCinema.edges.map(
      (edge) => edge.node
    );
    const cinemasWithCoords = cinemas.filter((cinema) => cinema.address);
    const mapUrl = "http://{s}.tile.osm.org/{z}/{x}/{y}.png";
    const attribution =
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    const defaultLat = 51;
    const defaultLon = 8.09;

    const startPosition = [
      queryParams.lat || defaultLat,
      queryParams.lon || defaultLon,
    ];
    const zoom = queryParams.zoom | 5;
    return (
      <Layout>
        {typeof window !== "undefined" && (
          <Map center={startPosition} zoom={zoom}>
            <TileLayer url={mapUrl} attribution={attribution} />
            {cinemasWithCoords.map((cinema) => renderMarker(cinema))}
          </Map>
        )}
      </Layout>
    );
  }
}

export default MapView;

export const query = graphql`
  query {
    allContentfulCinema {
      edges {
        node {
          id
          title
          url
          street
          city
          state
          zipCode
          country
          yearOfConstruction
          address {
            lon
            lat
          }
        }
      }
    }
  }
`;
